import React from 'react';
import VoteButtons from '../VoteButtons/VoteButtons';
import styles from '../Translation.module.css';

const TranslationPost = ({ post, translationLanguage }) => {
    return (
        <div 
            key={post.id} 
            className={styles.communityTranslation}>
            <VoteButtons post={post} language={translationLanguage} />
            <div className={`poemLine poemLine1 ${styles.poemLine}`}>{post.line1} </div>
            <div className={`poemLine ${styles.poemLine}`}>{post.line2} </div>
            <div className={`poemLine ${styles.poemLine}`}>{post.line3} </div>
            <div className='username'>
                {post.username}
            </div>
            <div className='date'>
                {post.createdAt}
            </div>
        </div>
    )
}

export default TranslationPost;