import React, { useEffect } from 'react';
import Button from '../../../UI/Button/Button';
import poem from '../../../../lib/poem/poem.json';

const SubmitNewTranslation = ({ language, line1, line2, line3, username, createdAt, updatedAt, setCreatedAt, setUpdatedAt, previousTranslationState, handleSubmit, isSaving }) => {

    useEffect(() => {
        const setDate = () => {
            const date = new Date();
            setCreatedAt(date.toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' }));
            setUpdatedAt(date.toLocaleString([], { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' }));
            // setCreatedAt(date.toUTCString());
            // setUpdatedAt(date.toUTCString());
        }

        setDate();
        
    }, [setCreatedAt, setUpdatedAt]);

    return (
        <div
            id='newTranslation'>
            <div className='poemLine poemLine1 submitting'>{line1}</div>
            <div className='poemLine submitting'>{line2}</div>
            <div className='poemLine submitting'>{line3}</div>
            <div 
                className='username'
            >
                {username}
            </div>
            <div className='date'>{createdAt}</div>
            <Button
                handleClick={handleSubmit}
                isLoading={isSaving}
                type='nextArrow'>
                {poem[language].submitButtonName}
            </Button>
            <Button 
                handleClick={previousTranslationState}
                type='backArrow'>
                {poem[language].backButtonName}
            </Button>
    </div>
    )
};

export default SubmitNewTranslation;