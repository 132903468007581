import React from 'react';
import poem from '../../lib/poem/poem.json';
import styles from './Original.module.css';

const Original = ({ language }) => {
    return (
        <div className={styles.originalPoem}>
            <h1 className='serif' >{language}</h1>
            <div className='poemLine poemLine1'>{poem.rm.line1}</div>
            <div className='poemLine'>{poem.rm.line2}</div>
            <div className='poemLine'>{poem.rm.line3}</div>
            <div className='author'>
                <a className="authorLink" href={poem.rm.authorLink}>{poem.rm.author}</a>, 2020 <br />
                <a className="backLink" href='/thetranslationissue'>← Back</a>
            </div>
        </div>
    )
}

export default Original;