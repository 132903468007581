import React, { useState } from 'react';
import AddNewTranslation from './AddNewTranslation/AddNewTranslation';
import TranslatedPost from './TranslatedPost/TranslatedPost';
import OriginalTranslation from './OriginalTranslation/OriginalTranslation';
import Button from '../UI/Button/Button';
import poem from '../../lib/poem/poem.json';
import styles from './Translation.module.css';

// translation states:
// 0: textarea own translation 
// 1: textarea own name
// 2: submit own translation / name
// 3: see all tranlations
const textareaTranslation = 0;
const textareaName = 1;
const submitTranslation = 2;
const allTranslations = 3;

const Translation = ({ id, language, posts, clicked, isLoading }) => {
    const [translationState, setTranslationState] = useState(0);

    let hasPosts = false;
    if (posts !== undefined){
        hasPosts = true;
    }

    const nextTranslationState = () => {
        if(translationState < allTranslations){
            setTranslationState(translationState+1);
        }
    }

    const skipToAllTranslations = () => {
        setTranslationState(allTranslations);
    }

    const backToTextareaTranslation = () => {
        setTranslationState(textareaTranslation);
    }
    
    const previousTranslationState = () => {
        if(translationState > textareaTranslation){
            setTranslationState(translationState-1);
        }
    }


    const selectLanguageHandler = () => {
        let translations = document.getElementsByClassName(styles.translation);
        let thisTranslation = document.getElementById(language);
        
        for (let i = 0; i < translations.length; i++){
            if ( translations[i] !== thisTranslation) {
                translations[i].classList.remove(styles.selectedLanguage);
                translations[i].classList.add(styles.collapsedLanguage);
                // let languageOfTranslation = translations[i].id;
            } else if ( translations[i] === thisTranslation ) {
                thisTranslation.classList.add(styles.selectedLanguage);
                thisTranslation.classList.remove(styles.collapsedLanguage);
                // let languageOfTranslation = thisTranslation.id;
            }
        }
    }

    const loadingElement = <div>...</div>

    return (
        <div 
            id={id}
            className={`${styles.translation} ${styles[language]} ${styles.translatedPoem}`} 
            onClick={selectLanguageHandler} >
            
            <h1 className='serif'>
                {language}
            </h1> 
            {(translationState < allTranslations) &&
                <>
                    <AddNewTranslation 
                        translationLanguage={language} 
                        textareaTranslation={textareaTranslation} 
                        textareaName={textareaName} 
                        submitTranslation={submitTranslation} 
                        currentTranslationState={translationState} 
                        previousTranslationState={previousTranslationState}
                        skipToAllTranslations={skipToAllTranslations}
                        nextTranslationState={nextTranslationState} 
                    />
                </>
            }

            {(translationState === allTranslations) &&
                <>
                    {isLoading && loadingElement}
                    <div className={styles.allTranslations}>
                    <OriginalTranslation translationLanguage={language} />
                    {hasPosts && posts.map((post) => (
                        <TranslatedPost post={post} key={post.id} translationLanguage={language} />
                    ))}
                    </div>
                    <Button 
                        handleClick={backToTextareaTranslation}
                        type='alignToBottom'
                    >
                        {poem[language].writeYourOwnTranslationButtonName}
                    </Button>
                </>
            }
 
        </div>
    )
}

export default Translation;