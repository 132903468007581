import React from 'react';
import styles from './Button.module.css';


const Button = ({ handleClick, isLoading, isDisabled, type, children }) => {

    let arrow = '';

    switch (type){
        case 'arrowUp':
            break;
        case 'arrowDown':
            break;
        case 'fixToBottom':
            arrow = <div className={styles.arrowNext}></div>;
            break;
        case 'alignToBottom':
            arrow = <div className={styles.arrowBack}></div>;
            break;
        case 'nextArrow':
            arrow = <div className={styles.arrowNext}></div>;
            break;
        case 'backArrow':
            arrow = <div className={styles.arrowBack}></div>;
            break;
        default: 
            break;
    }
    
    
    // disable vote buttons after voting
    let isVoteButton = false;
    if (type === 'arrowUp' || type === 'arrowDown' ){
        isVoteButton = true;
    }

    return (
        <button 
            className={`${styles[type]} ${styles.button} poemLine serif ${isDisabled && `${styles.disabled} disabled`}`}
            disabled={isVoteButton ? isDisabled : false}
            onClick={handleClick}
            >
            {isLoading ? '...' : children}
            {arrow}
        </button>
    )
};

export default Button; 