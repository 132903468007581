import React, { useEffect, useState } from 'react';
import Original from './components/Original/Original';
import Translation from './components/Translation/Translation';
import db from './lib/firebase';
import styles from './App.module.css';
import ReactGA from 'react-ga';

const languages = ['de', 'fr', 'it', 'en'];

const App = () => {
  useEffect(() => {
    // Google Analytics
    const trackingId = "UA-157156777-1"; 
    ReactGA.initialize(trackingId);
    ReactGA.pageview('/thetranslationissue/flurinabadel');

    // set height (fix for problems with mobile vh - menu)
    setHeight();
    window.addEventListener("resize", setHeight);
  },[]);

  const setHeight = () => {
    document.getElementById('appContainer').style.height = window.innerHeight + 'px';
  }

  // const [posts, setPosts] = useState([]);
  const [postsDe, setPostsDe] = useState([]);
  const [postsFr, setPostsFr] = useState([]);
  const [postsIt, setPostsIt] = useState([]);
  const [postsEn, setPostsEn] = useState([]);
  const [isLoading, setLoading] = useState(true);

  // hide overlay on click
  useEffect(() => {
    const hideOverlay = () => {
      const overlayDiv = document.getElementById('overlay');
      const transitionTime = '300'
      overlayDiv.style.transition = `opacity ${transitionTime}ms ease-in-out`;
      overlayDiv.style.opacity = 0;
      setTimeout(() => {
            overlayDiv.parentNode.removeChild(overlayDiv);
      }, 300);
    }

    // eventlistener to hide overlay
    document.body.addEventListener('click', hideOverlay, {once : true});
  }, []);

  useEffect(() => {
    // Hook to handle the initial fetching of posts

    for (let i = 0; i < languages.length; i++){
      db.collection('posts-' + languages[i])
        // .orderBy('createdAt', 'desc')
        .get()
        .then((querySnapshot) => {
          const data = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data()
          }));

          orderByVotes(data);

          switch(languages[i]){
            case 'de': 
              setPostsDe(data);
              break;
            case 'fr':
              setPostsFr(data);
              break;
            case 'it':
              setPostsIt(data);
              break;
            case 'en':
              setPostsEn(data);
              break;
            default:
              console.log('error setting posts');
              break;
          }

          setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    // Hook to handle the real-time updating of posts whenever there is a
    // change in the datastore (https://firebase.google.com/docs/firestore/query-data/listen#view_changes_between_snapshots)

    for (let i = 0; i < languages.length; i++){
      db.collection('posts-' + languages[i])
        // .orderBy('createdAt', 'desc')
        .onSnapshot((querySnapshot) => {
          const _posts = [];
          
          querySnapshot.forEach((doc) => {
            _posts.push({
              id: doc.id,
              ...doc.data(),
            });
          });

          orderByVotes(_posts);

          switch(languages[i]){
            case 'de': 
              setPostsDe(_posts);
              break;
            case 'fr':
              setPostsFr(_posts);
              break;
            case 'it':
              setPostsIt(_posts);
              break;
            case 'en':
              setPostsEn(_posts);
              break;
            default:
              console.log('error setting posts');
              break;
          }

        });
    }
  }, []);

  const orderByVotes = (data) => {
    data.sort((a,b) => {
      return (a.downVotesCount-a.upVotesCount) - (b.downVotesCount-b.upVotesCount) || a.updatedAt - b.updatedAt;
    });
  };

  return (
    <>
      <div 
        id='overlay'
        className={styles.overlay} >
      </div>
      <div 
        id='appContainer' 
        className={styles.container} >
        <Original language='rm' />
        <Translation 
          isLoading={isLoading}
          id='de'
          language='de' 
          posts={postsDe} />
        <Translation
          isLoading={isLoading}
          id='en'
          language='en' 
          posts={postsEn} />
        <Translation 
          isLoading={isLoading}
          id='fr'
          language='fr' 
          posts={postsFr} />
        <Translation 
          isLoading={isLoading}
          id='it'
          language='it' 
          posts={postsIt} />
      </div>
    </>
  );
}

export default App;
