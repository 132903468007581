import React from 'react';
import poem from '../../../lib/poem/poem';
import styles from '../Translation.module.css';

const OriginalTranslation = ({ translationLanguage }) => {
    return (
        <div className={`${styles.originalTranslation} originalTranslation`}>
            <div className={styles.starImage}></div>
            <div className={`poemLine poemLine1 ${styles.poemLine}`}> {poem[translationLanguage].line1} </div>
            <div className={`poemLine ${styles.poemLine}`}> {poem[translationLanguage].line2} </div>
            <div className={`poemLine ${styles.poemLine}`}> {poem[translationLanguage].line3} </div>
            <div className='translator'> 
                {poem[translationLanguage].translation} <a href={poem[translationLanguage].authorLink}>{poem[translationLanguage].author}</a>
            </div>
        </div>
    )
}

export default OriginalTranslation;