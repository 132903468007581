import React, { useState } from 'react';
import db from '../../../lib/firebase.js';
import Button from '../../UI/Button/Button';
import SubmitNewTranslation from './SubmitNewTranslation/SubmitNewTranslation';
import poem from '../../../lib/poem/poem.json';

const AddNewTranslation = ({ translationLanguage, textareaTranslation, textareaName, submitTranslation, currentTranslationState, previousTranslationState, skipToAllTranslations, nextTranslationState }) => {
    const [line1, setLine1] = useState('');
    const [line2, setLine2] = useState('');
    const [line3, setLine3] = useState('');
    const [username, setUsername] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [updatedAt, setUpdatedAt] = useState('');
    const [isSaving, setSaving] = useState(false);

    const handleSubmit = async () => {
        setSaving(true);

        await db.collection('posts-' + translationLanguage).add({
            line1,
            line2,
            line3,
            username,
            upVotesCount: 0,
            downVotesCount: 0,
            createdAt,
            updatedAt,
        });

        setLine1('');
        setLine2('');
        setLine3('');
        setUsername('');
        setCreatedAt('');
        setUpdatedAt('');
        setSaving(false);
        nextTranslationState();
    }

    const validationBoxShadow = '0px 0px 5px 0px var(--neongreen) inset';

    const validateOwnTranslationInput = () => {
        let poemLine1 = document.getElementById(`poemLine1-${translationLanguage}`);
        let poemLine2 = document.getElementById(`poemLine2-${translationLanguage}`);
        let poemLine3 = document.getElementById(`poemLine3-${translationLanguage}`);

        if(!line1.trim()){
            poemLine1.style.boxShadow = validationBoxShadow;
        } else {
            poemLine1.style.boxShadow = 'none';
        }

        if(!line2.trim()){
            poemLine2.style.boxShadow = validationBoxShadow;
        } else {
            poemLine2.style.boxShadow = 'none';
        }

        if(!line3.trim()){
            poemLine3.style.boxShadow = validationBoxShadow;
        } else {
            poemLine3.style.boxShadow = 'none';
        }
        
        if((line1.trim() && line2.trim() && line3.trim())){
            nextTranslationState();
        }
    }

    const validateNameInput = () => {
        let nameLine = document.getElementById(`nameLine-${translationLanguage}`);
        
        if(!username.trim()){
            nameLine.style.boxShadow = validationBoxShadow;
        } else {
            nameLine.style.boxShadow = 'none';
            nextTranslationState();
        }
  
    }
  
    const disableBoxShadowOfOwnTranslation = () => {
        let poemLine1 = document.getElementById(`poemLine1-${translationLanguage}`);
        let poemLine2 = document.getElementById(`poemLine2-${translationLanguage}`);
        let poemLine3 = document.getElementById(`poemLine3-${translationLanguage}`);

        poemLine1.style.boxShadow = 'none';
        poemLine2.style.boxShadow = 'none';
        poemLine3.style.boxShadow = 'none';
    }

    const disableBoxShadowOfName = () => {
        let nameLine = document.getElementById(`nameLine-${translationLanguage}`);
        
        nameLine.style.boxShadow = 'none';
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter'){
            event.preventDefault();
        }
    }


    return(
        <>
            {currentTranslationState === textareaTranslation &&
                <>
                <div id='newTranslation'>
                    <textarea
                        id={`poemLine1-${translationLanguage}`}
                        className='poemLine poemLine1'
                        maxLength='50'
                        spellCheck='false'
                        rows='1'
                        type='line1'
                        value={line1}
                        onKeyPress={handleKeyPress}
                        placeholder={poem[translationLanguage].ownTranslationPromptLine1}
                        onChange={(e) => setLine1(e.target.value)}
                        onClick={disableBoxShadowOfOwnTranslation}
                    />
                    <textarea
                        id={`poemLine2-${translationLanguage}`}
                        className='poemLine'
                        maxLength='50'
                        spellCheck='false'
                        rows='1'
                        type='line2'
                        value={line2}
                        onKeyPress={handleKeyPress}
                        placeholder={poem[translationLanguage].ownTranslationPromptLine2}
                        onChange={(e) => setLine2(e.target.value)}
                        onClick={disableBoxShadowOfOwnTranslation}
                    />
                    <textarea
                        id={`poemLine3-${translationLanguage}`}
                        className='poemLine'
                        maxLength='50'
                        spellCheck='false'
                        rows='1'
                        type='line3'
                        value={line3}
                        onKeyPress={handleKeyPress}
                        placeholder={poem[translationLanguage].ownTranslationPromptLine3}
                        onChange={(e) => setLine3(e.target.value)}
                        onClick={disableBoxShadowOfOwnTranslation}
                    />
                    <Button 
                        handleClick={validateOwnTranslationInput}
                        isDisabled={!(line1.trim() && line2.trim() && line3.trim())}
                        type='nextArrow'
                    >
                        {poem[translationLanguage].nextButtonName}
                    </Button>
                </div>
                <Button 
                    handleClick={skipToAllTranslations}
                    type='fixToBottom' >
                    {poem[translationLanguage].viewTranslationsButtonName}
                </Button>
                </>
            }

            {currentTranslationState === textareaName &&
                <div id='newTranslation'>
                {/* <div
                    className='poemLine poemLine1'
                    >{poem[translationLanguage].namePrompt}</div> */}
                    <textarea
                        id={`nameLine-${translationLanguage}`}
                        className='poemLine poemLine1'
                        maxLength='50'
                        spellCheck='false'
                        rows='1'
                        type='username'
                        value={username}
                        onKeyPress={handleKeyPress}
                        placeholder={poem[translationLanguage].namePrompt}
                        onChange={(e) => setUsername(e.target.value)}
                        onClick={disableBoxShadowOfName}
                    />
                    <Button
                        handleClick={validateNameInput} 
                        isDisabled={!username.trim()}
                        type='nextArrow' >
                        {poem[translationLanguage].nextButtonName}
                    </Button>
                    <Button
                        handleClick={previousTranslationState} 
                        type='backArrow'>
                        {poem[translationLanguage].backButtonName}
                    </Button>
                </div>
            }

            {currentTranslationState === submitTranslation &&
                <SubmitNewTranslation
                    language={translationLanguage}
                    line1={line1}
                    line2={line2}
                    line3={line3}
                    username={username}
                    createdAt={createdAt}
                    updatedAt={updatedAt}
                    setCreatedAt={setCreatedAt}
                    setUpdatedAt={setUpdatedAt}
                    previousTranslationState={previousTranslationState}
                    handleSubmit={handleSubmit}
                    isSaving={isSaving}
                    />
            }
        </>
    )
}

export default AddNewTranslation;