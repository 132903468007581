import React, { useEffect, useState } from 'react';
import db from '../../../lib/firebase'
import Button from '../../UI/Button/Button';

// outside of VoteButtons to not be overwritten
// for disabling several buttons in succession
let votedPostIds = [];

// for development purposes only
localStorage.clear();

const VoteButtons = ({ post, language }) => {

    const [isVoting, setVoting] = useState(false);
    const [votedPosts, setVotedPosts] = useState([]);

    useEffect(() => {
        // Fetch the previously voted items from localStorage. See https://stackoverflow.com/a/52607524/1928724 on why we need "JSON.parse" and update the item on localStorage. Return "true" if the user has already voted the post.
        const votesFromLocalStorage = localStorage.getItem('votes') || [];
        let previousVotes = [];

        try {
            // Parse the value of the item from localStorage. If the value of the
            // items isn't an array, then JS will throw an error.
            previousVotes = JSON.parse(votesFromLocalStorage);
        } catch (error) {
            // console.error(error);
        }

        setVotedPosts(previousVotes);
    }, []);

    const handleDisablingOfVoting = (postId) => {
        // This function is responsible for disabling the voting button after a
        // user has voted. Fetch the previously voted items from localStorage. See
        // https://stackoverflow.com/a/52607524/1928724 on why we need "JSON.parse"
        // and update the item on localStorage.
        let previousVotes = votedPosts;

        votedPostIds.push(postId);
        previousVotes.push(...votedPostIds);

        setVotedPosts(previousVotes);

        // Update the voted items from localStorage. See https://stackoverflow.com/a/52607524/1928724 on why we need "JSON.stringify" and update the item on localStorage.
        localStorage.setItem("votes", JSON.stringify(votedPosts));
    };

    const handleClick = async (type) => {
        setVoting(true);

        const date = new Date();

        // Do calculation to save the vote.
        let upVotesCount = post.upVotesCount;
        let downVotesCount = post.downVotesCount;

        if (type === "upvote") {
            upVotesCount = upVotesCount + 1;
        } else {
            downVotesCount = downVotesCount + 1;
        }

        await db.collection('posts-' + language).doc(post.id).set({
            line1: post.line1,
            line2: post.line2,
            line3: post.line3,
            username: post.username,
            upVotesCount, 
            downVotesCount,
            createdAt: post.createdAt,
            updatedAt: date.toUTCString(),
        });

        // Disable the voting button once the voting is successful.
        handleDisablingOfVoting(post.id);

        setVoting(false);
    };

    const checkIfPostIsAlreadyVoted = () => {
        if (votedPosts.indexOf(post.id) > -1) {
            return true;
        } else {
            return false;
        }
    }

    let totalVotes = post.upVotesCount-post.downVotesCount;

    return (
        <div className='voteButtonContainer'>
            <Button
                handleClick={() => handleClick("upvote")}
                isLoading={isVoting}
                isDisabled={checkIfPostIsAlreadyVoted()}
                type='arrowUp'
            />
            <Button
                type='totalVotes'
                >
                {totalVotes}
            </Button>
            <Button
                handleClick={() => handleClick("downvote")}
                isLoading={isVoting}
                isDisabled={checkIfPostIsAlreadyVoted()}
                type='arrowDown'
            /> 
        </div>
    )
}

export default VoteButtons;